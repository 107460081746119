export default class Header {

  constructor() {
    this.init();
  }

  init() {
    this.burger();
    this.isScrolled();
    this.addEvents();
    this.search();
    this.toggleMobileMenuItems();
    //this.menuAnim();
    //this.menuTopPosition();
    //this.heroOffset();
  }

  isScrolled() {
    window.onscroll = (e) => {
      window.scrollY > 0 ?
      document.querySelector('body').classList.add('is-scrolled') :
      document.querySelector('body').classList.remove('is-scrolled')
    }
  }

  menuAnim() {
    document.querySelector('.js-header-megamenu').addEventListener('animationend', () => {
      if(document.querySelector('body').classList.contains('is-slideOut')) {
        document.querySelector('body').classList.remove('is-slideOut');
      }
    });
  }

  menuTopPosition() {
    const bigMenu = document.getElementsByClassName('js-header-megamenu')[0]
    const mainHeader = document.getElementsByClassName('js-main-header')[0]
    bigMenu.style.top = mainHeader.getBoundingClientRect().height + 'px'
  }

  heroOffset() {
    const mainHeader = document.getElementsByClassName('js-main-header')[0]
    const heroOffset = document.getElementsByClassName('js-hero-offset')[0]

    if(window.innerWidth < 900 && heroOffset) heroOffset.style.paddingTop = (mainHeader.getBoundingClientRect().height + 10)+ 'px'
  }

  addEvents() {
    // const el = document.querySelector('.js-header-open-menu');
    // el.addEventListener('click', (e) => {
    //   e.stopPropagation();
    //  if( e.currentTarget.classList.contains('is-active')) {
    //   this.closeMegamenu();
    //  } else {
    //   this.openMegamenu()
    //  }
    // });

    // const langEl = document.querySelector('.js-lang-open');

    // if (langEl) {
    //   langEl.addEventListener('click', (e) => {
    //     e.stopPropagation();
    //     if( e.currentTarget.parentNode.classList.contains('is-open')) {
    //       e.currentTarget.parentNode.classList.remove('is-open');
    //     } else {
    //       e.currentTarget.parentNode.classList.add('is-open');
    //     }
    //   });
    // }

    const mobileToggleEl = document.querySelectorAll('.js-mobile-item-expand');
    mobileToggleEl.forEach(item => {
      item.addEventListener('click', (e) => {

        if( !e.currentTarget.parentNode.classList.contains('without-dropdown')) {
          if( matchMedia('(max-width: 990px)').matches) {

            if(e.currentTarget.parentNode.classList.contains('with-dropdown')){
                e.stopPropagation();
                if(e.currentTarget.parentNode.classList.contains('is-active')) {
                  e.currentTarget.parentNode.classList.remove('is-active');
                  e.currentTarget.parentNode.classList.remove('is-active');
                } else {
                  e.currentTarget.parentNode.classList.add('is-active');
                  e.currentTarget.parentNode.classList.add('is-active');
                }
               
            }
      
          } 

          if(!e.currentTarget.parentNode.classList.contains('with-dropdown')){
           
              e.stopPropagation();
              mobileToggleEl.forEach(itemin => {
                itemin.parentElement.classList.remove('is-active');
                itemin.classList.remove('is-active');
              })
              e.currentTarget.classList.add('is-active');
              e.currentTarget.parentNode.classList.add('is-active');
         }

        }

      });
    });
    // document.addEventListener('click', () => {
    //   this.closeMegamenu();
    //   document.querySelector('.js-lang-open').parentNode.classList.remove('is-open');
    // })
  }

  burger() {
    const tham = document.querySelector(".tham");
    const body = document.querySelector('body');;
    const header = document.querySelector('header')
    if(tham){
        tham.addEventListener('click', () => {
          tham.classList.toggle('tham-active');
          body.classList.toggle('is-menu-open');
          body.classList.toggle('overflow-hidden');
          if(tham.classList.contains('tham-active')) {
            var menu_active_items = header.querySelectorAll('.is-active');
            menu_active_items.forEach(itemin => {
              itemin.classList.remove('is-active');
            })
          }
      });
    }
  


  }

  openMegamenu() {
    document.querySelector('.js-header-open-menu').classList.add('is-active');
    document.querySelector('body').classList.add('is-open-menu');
    document.querySelector('body').classList.remove('is-mobile-menu-open');
  }

  closeMegamenu() {
    if(document.querySelector('.js-header-open-menu').classList.contains('is-active')) {
      document.querySelector('body').classList.add('is-slideOut');
    }
    document.querySelector('body').classList.remove('is-open-menu');
    document.querySelector('.js-header-open-menu').classList.remove('is-active');
  }

  toggleMobileMenuItems() {
    const navigationItems = document.querySelectorAll('.with-dropdown:not(.without-dropdown)>a');
    const jsBack = document.querySelectorAll('.js-back');
    navigationItems.forEach(item => {
        
      item.addEventListener('click', (e) => {
        if( e.currentTarget.tagName  === 'A') {
          if( matchMedia('(max-width: 990px)').matches) {
            e.preventDefault();
          } 
        }
        // if( e.currentTarget.nextElementSibling.tagName === 'DIV') {
        //   e.currentTarget.nextElementSibling.classList.toggle('is-active');
        // }
      });
    });

    jsBack.forEach(itemback => {
      itemback.addEventListener('click', (e) => {
        
        itemback.closest('.with-dropdown').classList.remove('is-active');
      })
    })

    if( matchMedia('(max-width: 900px)').matches) {
      
    } 
  }

  search() {
    const searchToggle = document.querySelector('.js-search-toggle');
    const searchInput = document.querySelector('.js-search-input');
    if(!searchToggle) return;
    searchToggle.addEventListener('click', () => {
      searchInput.classList.toggle('hidden');

    });
   
    
  }


}
