// import jQuery from "jquery";
// window.$ = window.jQuery = jQuery;

//import AOS from 'aos';
//import Animations from "./components/animations";
import Header from "./components/header";
//import carousels from "./components/swiper";
//import Accordions from "./components/acordions";
//import Vivus from 'vivus';
//import simpleParallax from 'simple-parallax-js';
import ScriptsTB from './components/scripts-tb';

//new Animations();
new Header();
//new Accordions();
new ScriptsTB();

