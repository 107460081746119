export default class ScriptsTB {
    constructor() {
        this.init();
    }

    init() {


        // $.fn.center = function () {
        //     this.css("position","absolute");
        //     this.css("top", ( $(window).height() - this.height() ) / 2+$(window).scrollTop() + "px");
        //     this.css("left", ( $(window).width() - this.width() ) / 2+$(window).scrollLeft() + "px");
        //     return this;
        // }

        //     $(document).ready(function(){

        //         /*	toggle mobile nav */

        //         $('.menu-link').click(function() {
        //             var duration = 300;
        //             var menu = $('.mobile-nav');
        //             if (menu.hasClass('is-active')) {
        //                 $('.menu-link').removeClass('is-active');
        //                 $('.mobile-nav li.with-dropdown').removeClass('is-active').children('ul').slideUp();
        //                      menu.slideUp(duration, function() {
        //                         menu.removeClass('is-active');
        //               });
        //             }
        //             else {
        //                 $('.menu-link').addClass("is-active");
        //                   menu.slideDown(duration, function() {
        //                 menu.addClass('is-active');
        //               });
        //             }
        //             return false;
        //         });


        //         $('.showFileInLayer').on('click', function (ev) {
        //       ev.preventDefault();
        //             var id_file = $(this).attr("id_file");
        //             var $fileLayer = $("#fileLayer");

        //             $fileLayer.html('<div style="margin: 20px; width: 360px; text-align: center;">Čekejte prosím...</div>');

        //             $.blockUI({
        //                 message: $fileLayer,
        //                 css: {
        //                     border: "none",
        //                     backgroundColor: "#FFFFFF",
        //                     width: "auto",
        //                     cursor: "default"
        //                 }
        //             });
        //             $(".blockOverlay").attr("title","Kliknutím zavřete");
        //       $(".blockOverlay").on("click touchstart", function(){
        //         $.unblockUI();
        //         $('.blockUI').remove();
        //       });
        //             //$('.blockUI.blockMsg').center();

        //             $.ajax({
        //                 url: "/issuu/getFile",
        //                 type: "POST",
        //                 data: {
        //                     id_file: id_file
        //                 },
        //                 cache: false,
        //                 success: function (serverData) {
        //                     $fileLayer.html(serverData);
        //                 },
        //                 error: function(jqXHR, textStatus, errorThrown) {
        //                     $fileLayer.html("Data se nepodařilo načíst, zkuste to prosím později.");
        //                     setTimeout(function(){
        //                         $.unblockUI();
        //                     },2000);

        //                 }
        //             });
        //         });

        //         /* prevent default action */

        // //	    $(".primary-nav .with-dropdown > a").click(function(){
        // //	         event.preventDefault()
        // //	    });

        //         $('.tabslide').on('click', 'tr:first', function (ev) {
        //                 //ev.preventDefault();
        //             $(this).next('.tabslide tr:last').toggle();
        //             $(this).closest(".tabslide").toggleClass("is-open");
        //         });

        //       var hash = window.location.hash;

        //       if (hash) {
        //         var $tabslidelink = $('a[href="'+hash+'"]' );
        //         if ($tabslidelink.length) {
        //           var $tabslide = $tabslidelink.closest('.tabslide');
        //           if ($tabslide.length) {
        //             $tabslide.find('tr:last-child').toggle();
        //             $tabslide.toggleClass('is-open');
        //           }
        //           $('html, body').animate({
        //                     scrollTop: $tabslidelink.offset().top - 100
        //                 }, 500);
        //         }
        //       }

        //         /* mobile nav collapsing */

        //         /* mobile nav collapsing */
        //         $(".mobile-nav li.with-dropdown > ul").hide();
        //         $(".mobile-nav li.with-dropdown > a").click(function(ev){
        //             ev.preventDefault();
        //             var evoker = $(this);
        //             evoker.parent().siblings().removeClass('is-active').find("ul:first").hide();
        //             $('html, body').animate({
        //                 scrollTop: evoker.offset().top
        //             }, 500);

        //             evoker.parent().toggleClass('is-active');

        //             evoker.parent().find("ul:first").slideToggle(600);

        //         });

        //         /* sticky nav */
        //         // if($(".navbar").length > 0)  {
        //         //     $(".navbar").stick_in_parent({
        //         //         parent: "body"
        //         //     });
        //         // }


        //         /* Search toggle */

        //         $('.search-toggle').click(function(){
        //             $('.form-search').slideToggle(200);
        //             $(this).toggleClass('is-active');
        //         });

        //          /* slider */

        //         $('.slider').slick({
        //             slide: '.slide',
        //             slidesToShow: 1,
        //             arrows: true,
        //             dots: true,
        //             autoplay: true,
        //             prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
        //             nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>'
        //         });

        //         /* tabs */

        //         $('.tabs-container').tabs();

        //         /* product detail tabs filter */
        //         $(".product-detail .tabs-container li a").each(function(){
        //             if (!$($(this).attr("href")).html().replace(/\s/g, "").length) {
        //                 $(this).closest('li').hide();
        //             }

        //         });

        //         /* accordion */

        //         $('.accordion h2').click(function () {
        //             $(this).next('.accordion-item__content').slideToggle(100);
        //             $(this).parent().toggleClass("is-closed");
        //             $(this).parent().siblings().addClass("is-closed");
        //             $(this).parent().siblings().children().next().slideUp(100);
        //             return false;
        //         });

        //         $('.contacts-detail .accordion h2').click(function (ev) {
        //             ev.preventDefault();

        //             if($(window).width() > 768)
        //             {
        //                 $(this).parent().find(".contacts-list-item:first").find('h3 > a').click();
        //             }

        //             $(this).parent().siblings().find('.contacts-list-item').addClass("is-closed");
        //             if ($(this).parent().is(".is-closed")) {
        //                 $(this).parent().find('.contacts-list-item').addClass("is-closed");
        //             }
        //         });

        //            $('.contacts-detail .contacts-list-item h3 a').click(function (ev) {
        //             ev.preventDefault();

        //             var $link = $(this);
        //             var $evoker = $(this).closest('h3');
        //             var $container = $evoker.next();
        //             $evoker.parent().toggleClass("is-closed");
        //             $evoker.parent().siblings().addClass("is-closed");
        //                $evoker.parent().siblings().find('.contacts-list-item__content').slideUp(100);
        //             setContactInfoLoading($container);
        //             $.ajax({
        //                 url: '/company/getContacts',
        //                 type: 'POST',
        //                 data: {
        //                     id_asset: $link.attr('id_asset'),
        //                     id_region: $link.attr('id_region'),
        //                     dispecink_type: $link.attr('dispecink_type')
        //                 },
        //                 cache: false,
        //                 success: function (serverData) {
        //                     var serverData = eval("(" + serverData + ")");

        //                     if (serverData.status) {
        //                         $container.html(serverData.html);
        //                         initMap($container);
        //             initPersonContact($container);
        //                     } else {
        //                         //nic nenalezeno
        //                         setContactInfoEmpty($container);
        //                     }

        //           $('html, body').animate({
        //                     scrollTop: $container.offset().top - 100
        //                 }, 500);
        //                 },
        //                 error: function(jqXHR, textStatus, errorThrown) {
        //                     setContactInfoEmpty($contactInfo);
        //                 }
        //             });

        //         });

        //         if ($(".contacts-detail .accordion-item").is(".is-closed")) {
        //             $(this).parent().find('.contacts-list-item').addClass("is-closed");
        //             $(this).parent().find('.contacts-list-item__content').slideUp(100);
        //         }


        //         $('.contacts-detail').find(".accordion-item:first").removeClass("is-closed");
        //         if($(window).width() > 768)
        //         {
        //             $('.contacts-detail').find(".accordion-item:first").find(".contacts-list-item:first").find('h3 > a').click();
        //         }


        //         /* gallery */

        //         $('.gallery a').magnificPopup({
        //           type: 'image',
        //           gallery:{
        //             enabled: true,
        //             tCounter: '%curr% z %total%'
        //           }
        //         });

        //         $('.large-image').magnificPopup({
        //           type: 'image'
        //         });

        //         /* tooltip */

        //         $('.tooltip').on('touchstart', function (e) {
        //             var link = $(this);
        //             if (link.hasClass('hover')) {
        //                 return true;
        //             } else {
        //                 link.addClass('hover');
        //                 $('.tooltip').not(this).removeClass('hover');
        //                 e.preventDefault();
        //                 return false;
        //             }
        //         });

        //         $('html').on('touchstart', function(e) {
        //             $('.tooltip').removeClass("hover");
        //             $('.tooltip__content').hide();
        //         })


        //         /* chosen */
        //         $('select').chosen({
        //           width: '100%',
        //             disable_search: true,
        //             inherit_select_classes: true
        //         });

        //         // questions
        //         $('#questions .item > p, .questions .item > p').each(function(){
        //           $(this).css('display', 'none');
        //         });

        //         $('#questions .item > h2, .questions .item > h2').click(function(){
        //           $(this).parent().toggleClass('is-active');
        //           $(this).parent().find('p').slideToggle(500);
        //         });

        //         // toggle list
        //         $('.toggle_list .item:not(.is-active) > .content').each(function(){
        //           $(this).css('display', 'none');
        //         });

        //         $('.toggle_list .item > h2').click(function(){
        //           $(this).closest('.item').toggleClass('is-active');
        //           $(this).closest('.item').find('.content').slideToggle(500);
        //         });


        //     $('#adminToggleCheckbox').click(function(){
        //         if($(this).find("i").hasClass("icon-ok")) {
        //             createCookie("ngsShowAdmin", 't', 1);
        //             $(".assetAdmin").show();
        //             $(this).find("i").removeClass("icon-ok");
        //             $(this).find("i").addClass("icon-remove");
        //             $(this).find("span").html("Skrýt administraci");
        //         } else {
        //             createCookie("ngsShowAdmin", 'f', 1);
        //             $(".assetAdmin").hide();
        //             $(this).find("i").removeClass("icon-remove");
        //             $(this).find("i").addClass("icon-ok");
        //             $(this).find("span").html("Zobrazit administraci");
        //         }
        //     });
        //         //jQuery(".assetAdmin").hide();
        //     initPersonContact($('body'));

        //     });

        //   function openNewFileLayer(id_file) {
        //       var $fileLayer = $("#fileLayer");

        //       $.fn.center = function () {
        //           this.css("position","absolute");
        //           this.css("top", ( $(window).height() - this.height() ) / 2+$(window).scrollTop() + "px");
        //           this.css("left", ( $(window).width() - this.width() ) / 2+$(window).scrollLeft() + "px");
        //           return this;
        //       }

        //       $fileLayer.html('<div style="margin: 20px; width: 360px; text-align: center;">Čekejte prosím...</div>');

        //       $.blockUI({
        //           message: $fileLayer,
        //           css: {
        //               border: "none",
        //               backgroundColor: "#FFFFFF",
        //               width: "auto",
        //               cursor: "default"
        //           }
        //       });
        //       $(".blockOverlay").attr("title","Kliknutím zavřete").click($.unblockUI);
        //       $(".blockOverlay").on("click touchstart", function(){
        //         $.unblockUI();
        //         $('.blockUI').remove();
        //       });

        //       $.ajax({
        //           url: "/issuu/getFile",
        //           type: "POST",
        //           data: {
        //               id_file: id_file
        //           },
        //           cache: false,
        //           success: function (serverData) {
        //               $fileLayer.html(serverData);
        //           },
        //           error: function(jqXHR, textStatus, errorThrown) {
        //               $fileLayer.html("Data se nepodařilo načíst, zkuste to prosím později.");
        //               setTimeout(function(){
        //                   $.unblockUI();
        //               },2000);

        //           }
        //       });
        //   }

        // function strstr(haystack, needle, bool) {
        //   //  discuss at: http://phpjs.org/functions/strstr/
        //   // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        //   // bugfixed by: Onno Marsman
        //   // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        //   //   example 1: strstr('Kevin van Zonneveld', 'van');
        //   //   returns 1: 'van Zonneveld'
        //   //   example 2: strstr('Kevin van Zonneveld', 'van', true);
        //   //   returns 2: 'Kevin '
        //   //   example 3: strstr('name@example.com', '@');
        //   //   returns 3: '@example.com'
        //   //   example 4: strstr('name@example.com', '@', true);
        //   //   returns 4: 'name'

        //   var pos = 0;

        //   haystack += '';
        //   pos = haystack.indexOf(needle);
        //   if (pos == -1) {
        //     return false;
        //   } else {
        //     if (bool) {
        //       return haystack.substr(0, pos);
        //     } else {
        //       return haystack.slice(pos);
        //     }
        //   }
        // }

        // function getURLParameter(name) {
        //   return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null
        // }

        // function setContactInfoLoading($contactInfo) {
        //     $contactInfo.html('<p align="center"><img src="/ngsAssetPlugin/images/admin/loading.gif" alt="Načítám..." /></p>');
        // }
        // function setContactInfoEmpty($contactInfo) {
        //     $contactInfo.html('<p align="center">Omlouváme se, ale požadované kontaktní údaje nebyly nalezeny</p>');
        // }
        // function initMap($contactInfo) {
        //         $contactInfo.find(".map_canvas").each(function(){
        //             var latitude = $(this).attr("latitude");
        //             var longitude = $(this).attr("longitude");
        //             var address_name = $(this).attr("address_name");
        //             var $mapCanvas = $(this);

        //             if (latitude && longitude) {
        //                 $(this).show();
        //                 var mapPosition = new google.maps.LatLng(latitude, longitude);

        //                 var myOptions = {
        //                     center: mapPosition,
        //                     zoom: 14,
        //                     mapTypeId: google.maps.MapTypeId.ROADMAP
        //                 };
        //                 var map = new google.maps.Map($mapCanvas[0],
        //                 myOptions);

        //                 var infowindow = new google.maps.InfoWindow({
        //                     content: address_name,
        //                     position: mapPosition
        //                 });
        //                 infowindow.open(map);

        //                 var marker = new google.maps.Marker({
        //                     position: mapPosition,
        //                     map: map,
        //                     title:address_name
        //                 });

        //                 google.maps.event.addListener(marker, 'click', function() {
        //                     infowindow.open(map,marker);
        //                 });

        //             }
        //         });
        //     }

        //     function initPersonContact($body) {
        //         $body.find('.person_contact').on("click", function(){
        //             $.blockUI({
        //                 message: $(this).next(),
        //                 css: {
        //                     border: "none",
        //                     backgroundColor: "#FFFFFF",
        //                     width: "auto",
        //                     cursor: "default",
        //                     left: ($(window).width() - $(this).next().width()) /2 + 'px'
        //                     //width: '620px'
        //                 }
        //             });
        //             $(".blockOverlay").attr("title","Kliknutím zavřete").click($.unblockUI);

        //             return false;
        //         });
        //         /*
        //         $(".blockOverlay").on("click touchstart", function(){
        //           $.unblockUI();
        //           $('.blockUI').remove();
        //         });
        //         */

        //         $body.find('.closeBlock').on("click", function(){
        //             $.unblockUI();
        //             return false;
        //         });
        //     }

        //     function createCookie(name,value,days) {
        //         var expires = "";
        //         if (days) {
        //             var date = new Date();
        //             date.setTime(date.getTime() + (days*24*60*60*1000));
        //             expires = "; expires=" + date.toUTCString();
        //         }
        //         document.cookie = name + "=" + value + expires + "; path=/";
        //     }

        //     function readCookie(name) {
        //         var nameEQ = name + "=";
        //         var ca = document.cookie.split(';');
        //         for(var i=0;i < ca.length;i++) {
        //             var c = ca[i];
        //             while (c.charAt(0)==' ') c = c.substring(1,c.length);
        //             if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        //         }
        //         return null;
        //     }

        // // analytics switch
        // (function ($) {
        //     $(document).ready(function () {
        //         var gaProperties = ['UA-2219753-4', 'UA-82167210-1']
        //         var disableStr = "ga-disable";
        //         if (document.cookie.indexOf(disableStr + "=true") > -1) {
        //             disableGA(true)
        //             $('.ga-switch input').prop('checked', false);
        //             statusText(true);
        //         } else {
        //             disableGA(false);
        //             statusText(false);
        //             $('.ga-switch input').prop('checked', true);
        //         }

        //         function statusText(status) {
        //             if (status) {
        //                 $('#ga-status span#ga-active-msg').hide();
        //                 $('#ga-status span#ga-inactive-msg').show();
        //             } else {
        //                 $('#ga-status span#ga-active-msg').show();
        //                 $('#ga-status span#ga-inactive-msg').hide();
        //             }
        //         }

        //         function gaOptout() {
        //             document.cookie = disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
        //             disableGA(true)
        //             statusText(true);
        //         }

        //         function gaOptin() {
        //             document.cookie = disableStr + "=false; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
        //             disableGA(false)
        //             statusText(false);
        //         }

        //         function disableGA(status) {
        //             gaProperties.forEach(function(gaCode) {
        //                 window['ga-disable-' + gaCode] = status;
        //             });
        //         }

        //         $('.ga-switch').click(function () {
        //             setTimeout(function(){
        //                 if (!$('.ga-switch input').is(':checked')) {
        //                     gaOptout();
        //                 }
        //             }, 1000);
        //         });

        //         $('.ga-switch').click(function () {
        //             setTimeout(function(){
        //                 if ($('.ga-switch input').is(':checked')) {
        //                     gaOptin();
        //                 }
        //             }, 1000);
        //         });

        //     });
        // })(jQuery);

        $(document)
            .ready(function () {
                var $win = $(window);
                var winH = $win.height();
                var $nav = $('.nfloating-box');
                var $lastScrollTop = 10;
                var $st = $(window)
                    .scrollTop();
                var sliderVideos = $('.swiper-slide video');
                var mySwiper = new Swiper('.swiper-container', {
                    // Optional parameters
                    direction: 'horizontal',
                    loop: true,
                    fadeEffect: {
                        crossFade: true,
                    },

                    // If we need pagination
                    paginationClickable: true,
                    pagination: {
                        el: '.pagination',
                        clickable: true,
                        renderBullet: function (index, className) {
                            var sliderArray = [];
                            var paginationBullets = $('.js-swiper-pagination');

                            var el = $('.swiper-container');
                            el.find('[data-name]')
                                .each(function () {
                                    sliderArray.push($(this)
                                        .data('name'));
                                });
                            paginationBullets.removeClass('hidden');
                            if (this.slides.length - 2 < 2) {
                                paginationBullets.addClass('hidden');
                            }
                            return '<span class="swiper-pagination_item ' + className + '"></span>';

                        },
                    },
                    on: {
                        init: function () {
                            var paginationCount = $('.js-pagination-count');
                            var paginationBullets2 = $('.js-swiper-pagination');
                            paginationCount.text(this.slides.length - 2 < 10 ? '0' + (this.slides.length - 2) : this.slides.length - 2);
                            var currentVideo = $('[data-swiper-slide-index=' + this.realIndex + ']')
                                .find('video');
                            if (this.slides.length - 2 < 2) {
                                paginationBullets2.addClass('hidden');
                            }
                            currentVideo.trigger('play');


                        },
                    },

                });


                if ($st > $lastScrollTop) {
                    $('.js-main-header')
                        .addClass('is-scrolled');
                }

                mySwiper.on('slideChange', function () {
                    var currentVideo = $('[data-swiper-slide-index=' + this.realIndex + ']')
                        .find('video');
                    var paginationActive = $('.js-pagination-active');
                    var paginationCount = $('.js-pagination-count');
                    paginationActive.text(this.realIndex + 1 < 10 ? '0' + (this.realIndex + 1) : this.realIndex + 1);
                    paginationCount.text(this.slides.length - 2 < 10 ? '0' + (this.slides.length - 2) : this.slides.length - 2);
                    sliderVideos.trigger('pause');
                    currentVideo.trigger('play');
                    $('.swiper-slide-active .swiper_video-controls')
                        .find('.btn-play img')
                        .attr('src', '/images/ico/pause.svg');
                    $('.swiper-slide-active .swiper_video-controls')
                        .find('.btn-play')
                        .removeClass('play')
                        .addClass('pause');
                });

                $('.swiper_video-controls button')
                    .click(function () {
                        if ($(this)
                            .hasClass('play')) {
                            $(this)
                                .removeClass('play')
                                .addClass('pause');
                            $(this)
                                .closest('.swiper-slide-active')
                                .find('video')
                                .trigger('play');
                            $(this)
                                .find('img')
                                .attr('src', '/images/ico/pause.svg');
                        } else if ($(this)
                            .hasClass('pause')) {
                            $(this)
                                .removeClass('pause')
                                .addClass('play');
                            $(this)
                                .closest('.swiper-slide-active')
                                .find('video')
                                .trigger('pause');
                            $(this)
                                .find('img')
                                .attr('src', '/images/ico/play.svg');
                        } else if ($(this)
                            .hasClass('mute')) {
                            $(this)
                                .closest('.swiper-slide-active')
                                .find('video')
                                .prop('muted', false);
                            $(this)
                                .removeClass('mute');
                            $(this)
                                .addClass('unmute');
                            $(this)
                                .find('img')
                                .attr('src', '/images/ico/mute-off.svg');
                        } else {
                            // createCookie('mutedVideo', 'yes', 24 * 31);
                            $(this)
                                .closest('.swiper-slide-active')
                                .find('video')
                                .prop('muted', true);
                            $(this)
                                .removeClass('unmute');
                            $(this)
                                .addClass('mute');
                            $(this)
                                .find('img')
                                .attr('src', '/images/ico/mute.svg');
                        }
                    });

                function video() {
                    var $video = $('.swiper-slide-active video');
                    if ($('.swiper-wrapper video').length > 0) {
                        if ($(this)
                            .scrollTop() > winH / 2) {
                            // $('.tns-slide-active video').closest('video').prop('muted', true);
                            if ($video.length > 0) {
                                $video.get(0)
                                    .pause();
                            }
                            $('.swiper-slide-active .swiper_video-controls')
                                .find('.btn-play')
                                .removeClass('pause')
                                .addClass('play');
                            $('.swiper-slide-active .swiper_video-controls')
                                .find('.btn-play img')
                                .attr('src', '/images/ico/play.svg');
                        } else {
                            if ($video.length > 0) {
                                $video.get(0)
                                    .play();
                            }
                            $('.swiper-slide-active .swiper_video-controls')
                                .find('.btn-play')
                                .removeClass('play')
                                .addClass('pause');
                            $('.swiper-slide-active .swiper_video-controls')
                                .find('.btn-play img')
                                .attr('src', '/images/ico/pause.svg');
                        }
                    }
                }

                // $(window).scroll(function () {
                //     video();

                //     $st = $(this).scrollTop();
                //     if ($st > 25) {
                //         $('.js-main-header').addClass('is-scrolled');
                //     } else {
                //         $('.js-main-header').removeClass('is-scrolled');
                //         if (window.innerWidth >= 768) {
                //             $('.js-main-header').removeClass('is-active');
                //         }
                //     }
                // });

                // $('.nheader_burger').click(function (e) {
                //     e.preventDefault();
                //     if (!$(this).parent('ul').hasClass('submenu') && !$(this).parent('ul').hasClass('submenu-in')) {
                //         $(this).toggleClass('active');
                //         $(this).parent().toggleClass('active');
                //     }
                //     $('body').toggleClass('overflow-hidden');
                // });

                // $('.nheader .col>ul>li>a').click(function (e) {
                //     if ($(window).width() < 1058) {
                //         if ($(this).next('ul').length > 0) {
                //             e.preventDefault();
                //             $(this).parent().toggleClass('active');
                //             $(this).next().slideToggle();
                //         }
                //     }
                // });

                $('.submenu li.with-dropdown:not(.without-dropdown)>a')
                    .click(function (e) {
                        if ($(window)
                            .width() < 1058) {
                            e.preventDefault();
                            $(this)
                                .closest('li')
                                .toggleClass('active');
                            // eslint-disable-next-line newline-per-chained-call
                            $(this)
                                .closest('li')
                                .find('.submenu-in')
                                .first()
                                .slideToggle();
                        }
                    });

                $win.bind('mousewheel DOMMouseScroll', function (e) {
                    var delta = e.originalEvent.wheelDelta
                        ? e.originalEvent.wheelDelta : -e.originalEvent.detail;

                    if (delta < 0) {
                        if ($(this)
                            .scrollTop() > 100) {
                            if (!$nav.hasClass('activeHide')) {
                                $nav.addClass('show');
                            }
                        }
                    } else if ($(this)
                        .scrollTop() < winH) {
                        if (!$nav.hasClass('activeHide')) {
                            $nav.removeClass('show');
                        }
                    }

                    if (window.location.pathname === '/') {
                        if ($(this)
                            .scrollTop() > winH) {
                            $('.nheader')
                                .removeClass('hp');
                            $('.nheader')
                                .addClass('active');
                        } else {
                            $('.nheader')
                                .addClass('hp');
                            $('.nheader')
                                .removeClass('active');
                        }
                    }
                });

                // if (window.location.pathname !== '/') {
                //     $('.nheader').addClass('active nhp');
                // }
                $('.box-search')
                    .click(function (e) {
                        if ($('.box-search')
                            .hasClass('show')) {
                            if (!$(e.target)
                                .closest('.box-search_in-form').length) {
                                $('.box-search')
                                    .removeClass('show');
                            }
                        }
                    });
                $('.show-search-betonarny')
                    .click(function (e) {
                        e.preventDefault();
                        $('.box-search-betonarny')
                            .addClass('show');
                        $('.box-search-betonarny')
                            .find('input[name="q"]')
                            .focus();
                    });
                $('.show-search-main')
                    .click(function (e) {
                        e.preventDefault();
                        $('.box-search-main')
                            .addClass('show');
                        $('.box-search-main')
                            .find('input[name="q"]')
                            .focus();
                    });
                $('.search-cross')
                    .click(function (e) {
                        e.preventDefault();
                        $('.box-search')
                            .removeClass('show');
                    });

                $('#box-search_in-form')
                    .bind('touchmove', function (ev) {
                        ev.preventDefault();
                    });

                // document.getElementById('box-search_in-form').addEventListener('touchmove', function (ev) {
                //     ev.preventDefault();
                // }, false);
            });

// $('img.svg').each(function () {
//     var $img = $(this);
//     var imgID = $img.attr('id');
//     var imgClass = $img.attr('class');
//     var imgURL = $img.attr('src');
//     $.get(imgURL, function (data) {
//         // Get the SVG tag, ignore the rest
//         var $svg = jQuery(data).find('svg');
//         // Add replaced image's ID to the new SVG
//         if (typeof imgID !== 'undefined') {
//             $svg = $svg.attr('id', imgID);
//         }
//         // Add replaced image's classes to the new SVG
//         if (typeof imgClass !== 'undefined') {
//             $svg = $svg.attr('class', imgClass + ' replaced-svg');
//         }
//         // Remove any invalid XML tags as per http://validator.w3.org
//         $svg = $svg.removeAttr('xmlns:a');
//         // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
//         if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
//             $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
//         }
//         // Replace image with new SVG
//         $img.replaceWith($svg);
//     }, 'xml');
// });

        $('.js-close')
            .click(function (e) {
                e.preventDefault();
                $(this)
                    .closest('div')
                    .hide();
            });

        $(document)
            .ready(function () {
                var tablesFiles = document.querySelectorAll('table.files');

                tablesFiles.forEach(function (table) {
                    var rows = table.querySelectorAll('tbody tr');
                    rows.forEach(function (row, i) {
                        if (i > 2) {
                            row.classList.add('hidden');
                        }
                    });
                    if (rows.length > 3) {
                        var showMore = document.createElement('tr');
                        showMore.classList.add('table-show-more');
                        var td = document.createElement('td');
                        td.setAttribute('colspan', '4');
                        var a = document.createElement('a');
                        a.setAttribute('href', '#');
                        a.classList.add('link');
                        a.textContent = 'Zobrazit více';
                        td.appendChild(a);
                        showMore.appendChild(td);
                        table.querySelector('tbody')
                            .appendChild(showMore);
                    }
                });

                document.querySelectorAll('.table-show-more')
                    .forEach(function (showMore) {
                        showMore.addEventListener('click', function (e) {
                            e.preventDefault();
                            var rows = showMore.closest('table')
                                .querySelectorAll('tbody tr');
                            rows.forEach(function (row, i) {
                                if (i > 2) {
                                    row.classList.remove('hidden');
                                }
                            });
                            showMore.remove();
                        });
                    });


                var dataSlug = document.querySelectorAll("div[data-slug]");
                if (dataSlug.length) {
                    dataSlug.forEach(function (item) {
                        item.addEventListener('click', function (e) {
                            e.preventDefault();
                            var slug = item.getAttribute('data-slug');
                            var newUrl = window.location.pathname + '?slug=' + slug;
                            window.history.pushState({ path: newUrl }, '', newUrl);
                        })
                    })
                }

                var current_url = new URL(window.location.href);
                var slug = current_url.searchParams.get("slug"); // Get 'slug' parameter safely

                if (slug) {
                    var element = document.querySelector(`div[data-slug="${slug}"]`);
                    var element_toggle_content = document.querySelector(`div[data-slug="${slug}"] .content`);

                    if (element && element_toggle_content) {
                        element.classList.add("is-active");
                        element_toggle_content.style.display = "block";

                        // Smooth scroll to the element
                        window.scrollTo({
                            top: element.offsetTop - 60, // Adjust offset for better positioning
                            behavior: "smooth"
                        });
                    }
                }

                


            })

    }
}


